import WithdrawForm from "@/sections/WithdrawForm";
import WithdrawFormAll from "@/sections/WithdrawFormAll";
import CopyButton from "@/components/ui/CopyButton";
import { useAuthStore } from "@/stores/authStore";
import { ContentCopy } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey, purple } from "@mui/material/colors";
import Image from "next/image";
import React from "react";
import { shallow } from "zustand/shallow";
import { useConfigStore } from "@/stores/configStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

type Props = {};

const Withdraw = (props: Props) => {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { data: promotion } = usePromotionHistory();

  return (
    <Box>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      <Divider>
        <Chip
          label="แจ้งถอนเครดิต"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      {/* <Box mt={2}>
        <Alert
          variant="outlined"
          severity="success"
          color="warning"
          component={Box}
          sx={{
            border: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
            "& .MuiAlert-icon": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
            },
            "& .MuiAlert-message": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
              borderLeft: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
              paddingLeft: 2,
            },
          }}
        >
          <AlertTitle
            sx={{
              fontWeight: 700,
              color: (theme) => theme.gradient[900]
            }}
          >
            ขั้นตอนการถอนเครดิต
          </AlertTitle >
          {
            (config?.is_withdraw_all || promotion) ? (
              <>
                1). จะทำการถอนเงินทั้งหมดออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            ) : (
              <>
                1). จะทำการถอนเงินโดยระบุจำนวนออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            )
          }
        </Alert>
      </Box> */}
      <Box borderRadius={1} mt={2}>
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"start"}
        mb={4}
        sx={{  p: 2, borderRadius: "8px" }} // Optional: Add background and padding to mimic the card style
      >
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          mr={2} // Add margin to space between the logo and text
        >
          <Image
            src={`${user?.bank_logo || ""}`}
            width={80}
            height={80}
            alt={`${user?.bank_code} BANK`}
          />
        </Box>

        <Box>
          <Typography
            variant="button"
            lineHeight={1.5}
            fontWeight={600}
            sx={{ color: "#FFFFFF" }} // White text to match the dark background
          >
            {user?.bank_name}
          </Typography>

          <Typography
            variant="body1"
            lineHeight={1.5}
            sx={{ 
              color: "#FFFFFF", 
              }}
          >
            <Typography
              component={"span"}
              color="#eceff4" 
              fontWeight={600}
              sx={{
                '@media (max-width:500px)': {
                  display: 'block'
                }}}
            >
              เลขที่บัญชี:{" "}
            </Typography>
            {user?.account_number}
          </Typography>

          <Typography
            variant="body1"
            lineHeight={1.5}
            sx={{ color: "#FFFFFF" }}
          >
            <Typography
              component={"span"}
              color="#eceff4" 
              fontWeight={600}
              sx={{
                '@media (max-width:500px)': {
                  display: 'block'
                }}}
            >
              ชื่อบัญชี:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
        </Box>
      </Box>

        <Box mt={2} sx={{ bgcolor: "#eceff4", borderRadius: 1}}>
          <Alert
            // sx={{ color: theme => theme.gradient[910] || theme.palette.warning.main }}
            variant="outlined"
            severity="warning"
            sx={{
              borderColor: "#4c566a"|| theme.palette.warning.main,
              color: theme => "#4c566a" || theme.palette.warning.main,
              "& .MuiAlert-icon": {
                color: (theme) =>  "#4c566a" || theme.palette.warning.main,
              }, 
            }}
            component={Box}
          >
            การถอนเงิน จะทั้งหมดออกจากกระเป๋า ใช้เวลา 2 นาที เงินจะเข้าโดยอัตโนมัติ
          </Alert>
        </Box>

        <Box mt={2}>
          {
            (config?.is_withdraw_all || promotion) 
              ? <WithdrawFormAll />
              : <WithdrawForm />
          }
        </Box>
        <Box mt={2} sx={{ bgcolor: "#eceff4", borderRadius: 1}}>
          <Alert
            // sx={{ color: theme => theme.gradient[910] || theme.palette.warning.main }}
            variant="outlined"
            severity="warning"
            sx={{
              borderColor: "#4c566a"|| theme.palette.warning.main,
              color: theme => "#4c566a" || theme.palette.warning.main,
              "& .MuiAlert-icon": {
                color: (theme) =>  "#4c566a" || theme.palette.warning.main,
              }, 
            }}
            component={Box}
          >
            ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้นโดย
          </Alert>
        </Box>

        
      </Box>
    </Box>
  );
};

export default Withdraw;

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Tabs, Tab, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  {
    key: "recommend",
    label: "ล็อบบี้",
    icon: "emojione-v1:cherries",
  },
  {
    key: "slot",
    label: "สล็อต",
    icon: "mdi:slot-machine-outline",
  },
  {
    key: "casino",
    label: "คาสิโน",
    icon: "maki:casino",
  },
  {
    key: "lottery",
    label: "หวย",
    icon: "fluent:lottery-24-filled",
  },
  {
    key: "sport",
    label: "กีฬา",
    icon: "fluent:sport-soccer-24-regular",
  },
];

export default function GameMenu(props: any) {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();

  const handleMenuClick = (_value: any, index: number) => {
    props.setCategory(index);
  };

  const filterMenus = config?.agent_name === 'wisdom168'
    ? menus.filter(menu => menu.key !== 'lottery')
    : menus

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              justifyContent: "center",
            }}
          >
            <Tabs
              orientation="horizontal" // Changed to horizontal
              value={props.category}
              onChange={handleMenuClick}
              variant="scrollable"
              aria-label="Horizontal tabs"
              indicatorColor={"none" as any}
              scrollButtons={false}
              sx={{
                marginTop: 1,
                borderBottom: 0,
                borderColor: "divider",
                alignItems: "center",
                justifyContent: "center",
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center", // Center the tabs
                },
              }}
            >
              {filterMenus.map((menu, index) => (
                <Tab
                  icon={
                    <Icon
                      id="icon"
                      icon={menu?.icon || ""}
                      width={24}
                      height={isMobile ? 40 : 60}
                      color={props.category === index ? "#fff" : theme.palette.primary.main} // Change icon color
                    />
                  }
                  iconPosition="top"
                  key={menu.key}
                  label={
                    <Box
                      component="span"
                      sx={{
                        color: props.category === index ? "#fff" : theme.palette.primary.main, // Change label color to white when selected
                      }}
                    >
                      {menu.label}
                    </Box>
                  }
                  sx={{
                    background:
                      props.category === index
                        ? theme.gradient[100]
                        : theme.gradient[400],
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "48px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                    ...(isMobile && {
                      minWidth: "100px",
                    }),
                  }}
                  disableRipple // Disable ripple effect for more control over styling
                />
              ))}
            </Tabs>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
    isWalletMenuOpen: boolean;
    isHeaderMenuOpen: boolean;
};

type Actions = {
    setOpen: (type: string, open: boolean) => void;
};

/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
export const useGlobalStore = createWithEqualityFn(
    devtools(
        immer<State & Actions>((set) => ({
            isHeaderMenuOpen: false,
            isWalletMenuOpen: false,
            setOpen: (type, open) => set((state) => {
                if (type == "WALLET") {
                    state.isWalletMenuOpen = open;
                } else if (type == "MENU") {
                    state.isHeaderMenuOpen = open;
                }
            }),
        }))
    ),
    Object.is
);
import { useAuthStore } from "@/stores/authStore";
import {
  Box, Chip,
  Divider,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { shallow } from "zustand/shallow";
import { useUserProfile } from "@/hooks";

type Props = {};

const UserProfile = (props: Props) => {
  const [user] = useAuthStore((state) => [state.user], shallow);
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  return (
    <Box>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      <Divider>
        <Chip
          label="ข้อมูลส่วนตัว"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      {/* <Box mt={2}>
        <Alert severity="info" component={Box}>
          <AlertTitle>ขั้นตอนการถอนเครดิต</AlertTitle>
          1). โปรดระบุจำนวนเงินที่ต้องการถอนเครดิต โดยมีขั้นต่ำที่ <br />
          2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
        </Alert>
      </Box> */}
      {/* <Box mt={2}>
        <Alert severity="warning">
          <AlertTitle>คำเตือน</AlertTitle>
          ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น
        </Alert>
      </Box> */}
     <Box p={2} borderRadius={1} mt={2} mb={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            {/* You can place an image here if needed */}
          </Box>

          <Typography variant="body1" lineHeight={1.5} mt={1}>
            {user?.bank_name}
          </Typography>

          {/* Wrapper for aligned text */}
          <Box width="100%" mt={1} display="flex" justifyContent="space-between">
            <Typography component={"span"} color="#eceff4">
              เลขที่บัญชี:
            </Typography><br />
            <Typography variant="body1">{user?.account_number}</Typography>
          </Box>

          <Box width="100%" mt={1} display="flex" justifyContent="space-between">
            <Typography component={"span"} color="#eceff4">
              ชื่อ-นามสกุล:
            </Typography><br />
            <Typography variant="body1">&nbsp;&nbsp; {user?.fullname}</Typography>
          </Box>

          <Box
            width="100%"
            mt={1}
            display="flex"
            justifyContent="space-between"
            onClick={() => navigator.clipboard.writeText(user?.username || "")}
          >
            <Typography component={"span"} color="#eceff4">
              เบอร์โทร:
            </Typography>
            <Typography variant="body1">{user?.username}</Typography>
          </Box>

          <Box
            width="100%"
            mt={1}
            display="flex"
            justifyContent="space-between"
            onClick={() => navigator.clipboard.writeText(user?.gameusername || "")}
          >
            <Typography component={"span"} color="#eceff4">
              ยูสเซอร์:
            </Typography>
            <Typography color="#eceff4" variant="body1">{user?.gameusername}</Typography>
          </Box>
        </Box>
      </Box>


      <Divider>
        <Chip
          label="โปรโมชั่น"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Box p={2} borderRadius={1} mt={2} mb={2}>
  <Box
    width="100%"
    mt={1}
    display="flex"
    justifyContent="space-between"
    onClick={() => navigator.clipboard.writeText(user?.username || "")}
  >
    <Typography component={"span"} color="#eceff4">
      โปรโมชั่น:
    </Typography>
    <Typography variant="body1">
      {promotionHistory
        ? isPromotionHistoryLoading
          ? "-"
          : promotionHistory?.promotion.name.length > 23
            ? promotionHistory.promotion.name.substring(0, 23) + "..."
            : promotionHistory.promotion.name
        : "-"}
    </Typography>
  </Box>      
</Box>


    </Box>
  );
};

export default UserProfile;

"use client";

import React, { useState, useEffect, useRef } from "react";
import { useUserProfile } from "@/hooks";
import { styled } from "@mui/material/styles";
import { useAuthStore } from "@/stores/authStore";
import { Alert, Box, Stack, Typography, IconButton, Button, alpha, Grid } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { shallow } from "zustand/shallow";

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  // { key: "historyAll", label: "ประวัติ", icon: "mdi:history" },
  { key: "affiliate", label: "แนะนำเพื่อน", icon: "mdi:invite" },
  { key: "refund", label: "ยอดเสีย", icon: "gridicons:refund" },
  { key: "promotion", label: "โปรโมชั่น", icon: "ph:gift-fill" },
  { key: "coupon", label: "คูปอง", icon: "mdi:coupon-outline" },
  { key: "wheel", label: "กงล้อ", icon: "lucide:ferris-wheel" },
  { key: "mysteryBox", label: "กล่องสุ่ม", icon: "ph:archive-bold" },
];

const Item = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "row", // Changed to horizontal for better alignment
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  transition: "all 0.12s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  "& #icon": {
    color: theme.palette.primary.contrastText,
  },
}));

const MenuWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Vertical layout
  overflowY: "auto",
  maxHeight: "80vh", // Add a max-height to prevent overflow
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export default function MainMenu() {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const {
    isLoading,
    currentBalance,
    handleRemovePro,
  } = useUserProfile();
  
  const [user] = useAuthStore((state) => [state.user], shallow); 
  const [setOpen, setData] = useAppModalStore((state) => [state.setOpen, state.setData]);

  const handleItemClick = (key: string) => {
    if (key === "removePro") {
      handleRemovePro();
    } else if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  return (
  <Stack 
    spacing={0} 
    sx={{ 
      paddingLeft: '1px',
      paddingRight: '1px',
      mt: 2, 
      // px: 3, 
      alignItems: 'center', // แนวนอน
      height: '100%', // ให้สูงเต็มหน้าจอเพื่อจัดแนวกลางในแนวตั้ง 
    }}
  >
  {user && (
    <MenuWrapper spacing={0}>
      {menus.map((menu) => (
        <Item key={menu.key} onClick={() => handleItemClick(menu.key)}>
            <Grid container spacing={0} alignItems="center"
            sx={{
              margin: '0px',
              paddingLeft: '0px',
              paddingRight: '0px',
                }}>
              <Grid item>
                <Icon icon={menu.icon || ""} width={32} height={32} id="icon" />
              </Grid>
              <Grid item>
                <Typography fontWeight="400" fontSize="12px">
                  {menu.label}
                </Typography>
              </Grid>
            </Grid>
        </Item>
      ))}
    </MenuWrapper>
  )}
</Stack>

  );
}

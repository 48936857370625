"use client";

import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";
import { useAuthStore } from "@/stores/authStore";
import {
  ChatRounded,
  Home,
  LoginOutlined,
  Person,
  MoneyOutlined,
  CurrencyExchange,
  Campaign,
  Chat,
} from "@mui/icons-material";
import { usePathname, useRouter } from "next/navigation";
import { shallow } from "zustand/shallow";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import useSiteSetting from "@/hooks/useSiteSetting";
import { useGlobalStore } from "@/stores/globalStore";
import { useUserProfile } from "@/hooks";
import { AccountBalance } from "@mui/icons-material"; // import the new icons

export interface IAppProps {}

type BottomNavigationActionItem = {
  path: string;
  label: string;
  icon: React.ReactNode;
  actionType: "navigate" | "modal" | "external" | "custom" ; 
};
 
const beforeLoginActions: BottomNavigationActionItem[] = [
  {
    path: "login",
    label: "เข้าสู่ระบบ",
    icon: <LoginOutlined />,
    actionType: "modal",
  },
  {
    path: "signUp",
    label: "สมัครสมาชิก",
    icon: <Person />,
    actionType: "modal",
  },
  {
    path: "Addline",
    label: "ติดต่อ",
    icon: <Chat  />,
    actionType: "external",
  },
];

const afterLoginActions: BottomNavigationActionItem[] = [
  {
    path: "/",
    label: "หน้าแรก",
    icon: <Home />,
    actionType: "navigate",
  },
  {
    path: "promotion",
    label: "โปรโมชั่น",
    icon: <Campaign />,
    actionType: "modal",
  },
  {
    path: "deposit",
    label: "ฝาก",
    icon: <AccountBalance />, // updated icon for deposit
    actionType: "modal",
  },
  {
    path: "withdraw",
    label: "ถอน",
    icon: <CurrencyExchange />, // updated icon for withdraw
    actionType: "modal",
  },
  {
    path: "Addline",
    label: "ติดต่อ",
    icon: <Chat />,
    actionType: "external",
  },
];

const afterLoginWithBonusActions: BottomNavigationActionItem[] = [
  {
    path: "/",
    label: "หน้าแรก",
    icon: <Home />,
    actionType: "navigate",
  },
  {
    path: "promotion",
    label: "โปรโมชั่น",
    icon: <Campaign />,
    actionType: "modal",
  },
  {
    path: "handleRemovePro",
    label: "ออกโปร",
    icon: <AccountBalance  />, // updated icon for handleRemovePro if needed
    actionType: "custom",
  },
  {
    path: "withdraw",
    label: "ถอน",
    icon: <CurrencyExchange />, // updated icon for withdraw
    actionType: "modal",
  },
  {
    path: "Addline",
    label: "ติดต่อ",
    icon: <Chat />,
    actionType: "external",
  },
];
export default function LayoutBottomNavigation(props: IAppProps) {
  const path = usePathname();
  const router = useRouter();
  const { lineOaUrl } = useSiteSetting();
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    isBonus,
    handleRemovePro,
    isPromotionHistoryLoading,
  } = useUserProfile();
  const [openHeaderMenu] = useGlobalStore((state) => [ state.setOpen], shallow)

  // Auth Modal
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);

  const actions = isLoggedIn
  ? isBonus || promotionHistory
    ? afterLoginWithBonusActions
    : afterLoginActions
  : beforeLoginActions;

  const handleAction = (action: BottomNavigationActionItem) => {
    if (action.actionType === "modal") {
      if (action.path === "login") {
        setOpenLoginModal(true);
      } else {
        setAppDataModal(action.path);
        setOpenAppModal(true);
      }
    } else if (action.actionType === "navigate") {
      router.push(action.path);
    } else if (action.actionType === "custom") {
      if (action.path === "handleRemovePro") {
        // ตรวจสอบ currentBalance ก่อนทำการ removePro
        if (currentBalance > 5) {
          alert("ไม่สามารถออกโปรได้เนื่องจากยอดคงเหลือมากกว่า 5");
        } else {
          handleRemovePro();
        }
      } else if (action.label === "เมนู") {
        openHeaderMenu("MENU", true);
      } else if (action.label === "ฝาก-ถอน") {
        openHeaderMenu("WALLET", true);
      }
    } else if (action.actionType === "external" && action.path === "Addline") {
      window.open(lineOaUrl, "_blank");
    }
  };
  

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.gradient[400],zIndex: 'modal'
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {actions.map((action, index) => (
          <BottomNavigationAction
            key={index}
            label={action.label}
            icon={action.icon}
            onClick={() => handleAction(action)}
          />
        ))}
      </BottomNavigation>
    </Container>
  );
}

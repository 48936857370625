"use client";

import React, { CSSProperties } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import "./style.css";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import Marquee from "../../components/carousel/Marquee";

type Props = {};
const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 32,
  height: 4,
  display: "inline-block",
  margin: "0 8px",
};

export default function BannerCarousel({ }: Props) {
  const theme = useTheme();
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  //ฟั่งชั่นเช็คขนาด จากหน้าจอ 
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: bannersliders, isLoading: isBannerSliderLoading } =
    useBannerSlider();

  if (isBannerSliderLoading) {
    return (
      <Grid sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <>
        <Marquee />
        <Carousel
          infiniteLoop
          autoPlay
          showArrows={false}
          centerMode={true} // Enable center mode
          showStatus={false}
          showThumbs={false}
          centerSlidePercentage={isMobile ? 100 : 33.33} // Show 3 slides on larger screens, 1 on mobile
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{
                    ...indicatorStyles,
                    borderRadius: theme.shape.borderRadius,
                    background: theme.palette.primary.main,
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={{
                  ...indicatorStyles,
                  borderRadius: theme.shape.borderRadius,
                  background: theme.palette.primary.main,
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {bannersliders?.map((banner) => (
            <Box
              key={banner?.desc}
              mx={{
                xs: 0,
                md: 1,
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: "16px",
                }}
                src={banner?.image}
                alt={banner?.desc} // Add alt attribute for accessibility
              />
            </Box>
          ))}
        </Carousel>
      </>
    );
  }
}
